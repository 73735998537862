import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { cn } from '@lib/utils';
import { Textarea, ITextareaProps } from '@ui/textarea';
import { FIELD_HEIGHT } from '@ui/lib';

type FormTextareaProps = {
  icon?: JSX.Element;
  error?: string;
  maxLength?: number;
} & ITextareaProps & { rootClassName?: string };

export const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  (
    {
      icon,
      error,
      maxLength = 255,
      rootClassName,
      onChange,
      size = 'lg',
      ...props
    },
    ref,
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => textareaRef.current!);

    const handleTextareaResize = (textarea?: HTMLTextAreaElement | null) => {
      if (!textarea) return;

      textarea.style.height = (FIELD_HEIGHT[size!] / 16)?.toString() + 'px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      handleTextareaResize(e.target);

      if (onChange) {
        onChange(e);
      }
    };

    useEffect(() => {
      handleTextareaResize(textareaRef.current);
      // TODO: check deps and add/remove missing if needed. Add comment if deps is as needed
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    return (
      <div className={cn('relative w-full', rootClassName)}>
        <Textarea
          size={size}
          {...props}
          ref={textareaRef}
          className={cn(
            'resize-none leading-relaxed',
            { 'pl-[3.25rem]': !!icon },
            props.className,
          )}
          onChange={handleChange}
          maxLength={maxLength}
          aria-invalid={!!error || props['aria-invalid']}
        />
        {!!icon && <div className="absolute left-4 top-4">{icon}</div>}
      </div>
    );
  },
);
