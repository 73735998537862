import { useEffect, useMemo, useState } from 'react';

import { Modal } from '@components/modal';
import { ALLOWED_CURRENCIES } from '@entities/currency';
import { useGetCurrencies } from '@entities/finance/model';
import { ECurrency } from '@lib/currency';
import { Loader } from '@ui/loader';

import { TOnCurrencyChange, TSelectedCurrency } from '../select/types';
import { ModalCurrencyButton } from './modal-currency-button';

const CURRENCY_DATA_ATTRIBUTE = 'data-currency';

export type SelectCurrencyModalProps = {
  onChange?: TOnCurrencyChange;
  selected?: TSelectedCurrency;
  title?: string;

  open?: boolean;
  setOpen?: (open: boolean) => void;
} & React.PropsWithChildren;

export const SelectCurrencyModal = ({
  selected,
  onChange,
  children,
  title,

  ...props
}: SelectCurrencyModalProps) => {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetCurrencies();

  const filteredCurrencies = useMemo(
    () =>
      ALLOWED_CURRENCIES.filter((allowedCurrency) =>
        data?.currencies?.includes(allowedCurrency),
      ),
    [data?.currencies],
  );

  const closeModal = () => {
    if (props.setOpen) {
      return props.setOpen(false);
    }

    setOpen(false);
  };

  const onCurrencyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onChange) return;

    const currency = e.currentTarget.getAttribute(
      CURRENCY_DATA_ATTRIBUTE,
    ) as ECurrency;

    if (!currency || !ALLOWED_CURRENCIES.includes(currency)) {
      return;
    }

    onChange(currency);

    setTimeout(closeModal, 100);
  };

  useEffect(() => {
    if (typeof props.open === 'boolean') {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <Modal
      title={title}
      trigger={children}
      open={open}
      onOpenChange={props.setOpen || setOpen}
    >
      <div className="flex max-h-[42vh] flex-col overflow-y-auto">
        {isLoading ? (
          <Loader />
        ) : (
          filteredCurrencies.map((currency, i) => {
            return (
              <ModalCurrencyButton
                key={`${currency}_${i}`}
                currency={currency}
                selected={selected}
                onClick={onCurrencyClick}
              />
            );
          }) || 'No data...'
        )}
      </div>
    </Modal>
  );
};
