import { ALLOWED_CURRENCIES } from '@entities/currency';
import { ECurrency } from '@lib/currency';

import { GenericStateCreator } from '../types';

interface CurrencyState {
  currency: ECurrency;
}

interface CurrencyActions {
  setCurrency: (currency: ECurrency) => void;
}

export type CurrencySlice = CurrencyState & CurrencyActions;

const initialState: CurrencyState = {
  currency: (localStorage.getItem('currency') || 'USD') as ECurrency,
};

export const createCurrencySlice: GenericStateCreator<CurrencySlice> = (
  set,
) => ({
  ...initialState,
  setCurrency: (newCurrency: ECurrency) => {
    if (!ALLOWED_CURRENCIES.includes(newCurrency)) {
      console.warn(`Invalid currency: ${newCurrency}`);
      return;
    }
    localStorage.setItem('currency', newCurrency);
    set((state) => {
      state.currency = newCurrency;
    });
  },
});
