import React, { createContext, useEffect, useState } from 'react';
import { DATA_ITEM_KEY } from '../lib';
import { ISelectBase, TDefaultValue, TOnSearchChange } from '../types';

export interface ISelectContext<TValue extends TDefaultValue = TDefaultValue>
  extends Omit<ISelectBase<TValue>, 'onChange'> {
  localeValue?: TValue;
  setLocaleValue?: (value?: TValue) => void;
  onChange?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SelectContext = createContext<ISelectContext>({
  isOpen: false,
});

export interface IProviderProps<TValue extends TDefaultValue = TDefaultValue>
  extends Omit<ISelectBase<TValue>, 'onSearchChange'>,
    React.PropsWithChildren {
  setSearch?: (value: string) => void;
}

export const Provider = <TValue extends TDefaultValue>({
  isOpen,

  value,
  onChange,
  onAccept,
  onCancel,
  onListOpen,
  onListClose,

  searchValue,
  setSearch,

  children,
}: IProviderProps<TValue>) => {
  const [localeValue, setLocaleValue] = useState<TValue | undefined>(value);

  const handleChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget?.getAttribute(DATA_ITEM_KEY) as
      | TValue
      | undefined;

    if (value === localeValue) {
      handleAccept();
    } else {
      setLocaleValue(value);
    }

    return value;
  };

  const handleAccept = () => {
    onChange && onChange(localeValue);

    if (onAccept) {
      return onAccept();
    }

    onListClose && onListClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      return onCancel();
    }

    onListClose && onListClose();
  };

  const onSearchChange: TOnSearchChange = (e) => {
    const value = e.target.value;

    setSearch && setSearch(value);
  };

  useEffect(() => {
    if (value === localeValue) return;

    setLocaleValue(value);
    // TODO: check deps and add/remove missing if needed. Add comment if deps is as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setSearch && setSearch('');

    if (value !== localeValue) {
      setLocaleValue(value);
    }
    // TODO: check deps and add/remove missing if needed. Add comment if deps is as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <SelectContext.Provider
      value={{
        isOpen,
        value,
        localeValue,
        onChange: handleChange,
        onCancel: handleCancel,
        onAccept: handleAccept,
        onSearchChange,
        searchValue,
        onListOpen,
        onListClose,
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};
