import { cn } from '@lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';

const containerVariants = cva('mx-auto flex w-full', {
  variants: {
    size: {
      sm: 'max-w-[23.125rem]',
      md: 'max-w-[25rem] ',
      '2xl': 'max-w-[31.25rem] xl:max-w-[55rem]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface IFullscreenModalContainerProps
  extends React.PropsWithChildren,
    VariantProps<typeof containerVariants> {
  title?: string;
  isCentered?: boolean;
  renderHeaderButton?: ReactNode;
  slotProps?: {
    body?: React.HTMLAttributes<HTMLDivElement>;
  };
}

export const FullscreenModalContainer = ({
  title,
  children,
  isCentered,
  size,
  renderHeaderButton,
  slotProps,
}: IFullscreenModalContainerProps) => {
  return (
    <div className="flex flex-1" data-test="fullscreen-modal-container">
      <div
        className={cn(containerVariants({ size }), {
          'pt-4': !isCentered,
        })}
      >
        <div className="flex max-w-full flex-1 flex-col">
          {(title || renderHeaderButton) && (
            <div className="flex gap-x-[14px]">
              {renderHeaderButton}
              {!!title && (
                <h2 className="py-[0.96875rem] text-2xl font-medium leading-[1.0625rem]">
                  {title}
                </h2>
              )}
            </div>
          )}
          <div
            {...slotProps?.body}
            className={cn(
              'py-8',
              {
                'my-auto': isCentered,
                'flex-1 md:m-0': !isCentered,
              },
              slotProps?.body?.className,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
