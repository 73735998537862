import { ECurrency } from '@lib/currency';

export const ALLOWED_CURRENCIES = [
  ECurrency.USD,
  ECurrency.EUR,
  ECurrency.GBP,
  ECurrency.AED,
  ECurrency.CHF,
  ECurrency.CNY,
  ECurrency.RUB,
];

export const currencyCodes: Partial<Record<ECurrency, string>> = {
  [ECurrency.USD]: 'us',
  [ECurrency.EUR]: 'eu',
  [ECurrency.GBP]: 'gb',
  [ECurrency.AED]: 'ae',
  [ECurrency.CHF]: 'ch',
  [ECurrency.CNY]: 'cn',
  [ECurrency.RUB]: 'ru',
};

export const CURRENCY_NAMES: Partial<Record<ECurrency, string>> = {
  [ECurrency.USD]: 'US Dollar',
  [ECurrency.EUR]: 'Euro',
  [ECurrency.GBP]: 'British Pound',
  [ECurrency.AED]: 'UAE Dirham',
  [ECurrency.CHF]: 'Swiss Franc',
  [ECurrency.CNY]: 'Chinese Yuan',
  [ECurrency.RUB]: 'Russian Ruble',
};

export const getCurrencies = () => {
  return Object.values(ECurrency);
};
