import { ECurrency } from '@lib/currency';
import { client } from '@shared/api';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type NotificationChannels = 'PUSH' | 'EMAIL' | 'TELEGRAM';

export interface IClientProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  photoUrl: string | null;
  address: string | null;
  defaultCurrency: ECurrency;
  isWelcomed: boolean;
  isTelegramConnected: boolean;
  createdAt: string;
  notificationChannels: NotificationChannels[];
}

export interface IUpdateProfilePayload {
  address?: string | null;
  defaultCurrency?: string;
  isWelcomed?: boolean;
}

export interface IClientConfig {
  exchangeCommissionPct: number;
  withdrawCommissionPct: number;
  depositCommissionPct: number;
  cardOutCommissionPct: number;
  cardInCommissionPct: number;
  invoiceCommissionPct: number;
  exchangeSameCurrencyCommissionPct: number;
  withdrawSameCurrencyCommissionPct: number;
  depositSameCurrencyCommissionPct: number;
  cardOutSameCurrencyCommissionPct: number;
  cardInSameCurrencyCommissionPct: number;
  invoiceSameCurrencyCommissionPct: number;
  cardDisburseCommissionPct: number;
  cardDisburseSameCurrencyCommissionPct: number;
  autoRefundThresholdAmount: number;
  autoRefundThresholdCurrency: string;
  cardInBadCurrencyCommissionPct: number;
  cardOutBadCurrencyCommissionPct: number;
  cardDisburseBadCurrencyCommissionPct: number;
  defaultCurrency: string;
  availableCurrencies: string[];
  maxDailyWithdrawalAmount: number;
  maxMonthlyWithdrawalAmount: number;
  minTransactionAmount: number;
  maxTransactionAmount: number;
  twoFactorAuthRequired: boolean;
  allowedCountries: string[];
  kycLevel: 'basic' | 'intermediate' | 'advanced';
  cardOrderingEnabled: boolean;
  internationalTransfersEnabled: boolean;
  cryptoTradingEnabled: boolean;
  savingsInterestRate: number;
  loanInterestRate: number;
  overdraftLimit: number;
  overdraftInterestRate: number;
}

export interface IUpdatePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

export interface ISetPhotoPayload {
  file?: File;
}

export interface IClientBalance {
  checking: number;
  saving: number;
}

export interface ICheckPasswordPayload {
  currentPassword: string;
}

export const useGetClientBalance = (
  currency: ECurrency,
  options?: Omit<
    UseQueryOptions<IClientBalance, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IClientBalance, AxiosError>({
    queryKey: ['clientBalance', currency],
    queryFn: () =>
      client
        .get('/client/balance', {
          params: { currency },
        })
        .then((res) => res.data),
    ...options,
  });

  return {
    balance: query.data,
    ...query,
  };
};

export const useUpdateClientProfile = () => {
  const mutation = useMutation<
    IClientProfile,
    AxiosError,
    IUpdateProfilePayload
  >({
    mutationFn: (payload) =>
      client.patch('/client/profile', payload).then((res) => res.data),
  });

  return {
    updateProfile: mutation.mutateAsync,
    ...mutation,
  };
};

export const queryKeyGetClientProfile = ['clientProfile'];

export const useGetClientProfile = (
  options?: Omit<
    UseQueryOptions<IClientProfile, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IClientProfile, AxiosError>({
    queryKey: queryKeyGetClientProfile,
    queryFn: () => client.get('/client/profile').then((res) => res.data),
    ...options,
  });

  return {
    profile: query.data,
    ...query,
  };
};

export const useGetClientConfig = (
  options?: Omit<
    UseQueryOptions<IClientConfig, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IClientConfig, AxiosError>({
    queryKey: ['clientConfig'],
    queryFn: () => client.get('/client/config').then((res) => res.data),
    ...options,
  });

  return {
    config: query.data,
    ...query,
  };
};

export const useSetClientPhoto = () => {
  return useMutation<IClientProfile, AxiosError, FormData>({
    mutationFn: (formData) =>
      client
        .post('/client/profile/photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),
  });
};

export const useUpdateClientPassword = () => {
  const mutation = useMutation<void, AxiosError, IUpdatePasswordPayload>({
    mutationFn: (payload) =>
      client.post('/auth/update-password', payload).then((res) => res.data),
  });

  return {
    updatePassword: mutation.mutateAsync,
    ...mutation,
  };
};

export const useCheckPassword = () => {
  const mutation = useMutation<
    { isCorrect: boolean },
    AxiosError,
    ICheckPasswordPayload
  >({
    mutationFn: (payload) =>
      client.post('/auth/check-password', payload).then((res) => res.data),
  });

  return {
    ...mutation,
  };
};
