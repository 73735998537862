import * as React from 'react';
import { cn } from '@/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { fieldBase, fieldVariants, textareaSizes } from './lib';

const textareaVariants = cva(fieldBase, {
  variants: {
    variant: fieldVariants,
    size: textareaSizes,
  },
  defaultVariants: {
    variant: 'dark',
    size: 'default',
  },
});

export interface ITextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea };
