import AddCircleIcon from '@assets/icons/add-circle.svg';
import MastercardIcon from '@assets/icons/mastercard.svg';
import { getFormattedCardNumber } from '@components/transactions/util';
import { ECardStatus } from '@entities/cards';
import { FreezeIcon } from '@icons/Freeze';
import { cn } from '@lib/utils';
import { Skeleton } from '@ui/skeleton';

export type TDashboardCardProps = {
  number?: string;
  type?: string;
  expiresAt?: string;
  isLoading?: boolean;
  onClick?: () => void;
  status?: ECardStatus;
  isNewCardButton?: boolean;
};

export const DashboardCard = ({
  number,
  isLoading,
  isNewCardButton,
  status,
  onClick = () => {},
}: TDashboardCardProps) => {
  if (isLoading) {
    return <Skeleton className="h-[9.875rem] w-[18.75rem] rounded-2xl" />;
  }

  const isInactive = status === ECardStatus.frozen;

  const bg =
    isInactive || isNewCardButton
      ? 'transparent'
      : `radial-gradient(70% 70% at 50% 50%,
    rgba(102, 108, 123, 0.50) 0%, rgba(102, 108, 123, 0.22) 50%, rgba(102, 108, 123, 0.00) 100%)`;

  return (
    <div
      data-test={isNewCardButton ? 'bank-card-new' : 'bank-card'}
      className={`shadow-[0px 24px 64px 0px rgba(0, 0, 0, 0.15)] z-1
        flex h-[9.875rem] min-h-[177px] w-[18.75rem] cursor-pointer flex-col rounded-2xl border-[1px] border-card-stroke/20 p-6 text-[0.6875rem] font-medium tracking-widest`}
      style={{
        background: bg,
        boxShadow: '0px 24px 64px 0px rgba(0, 0, 0, 0.15)',
      }}
      onClick={onClick}
    >
      {isNewCardButton && (
        <img
          src={AddCircleIcon}
          className="m-auto w-[30%]"
          alt="Add new card"
        />
      )}
      {!isNewCardButton && (
        <>
          <div className="flex items-center justify-between leading-[0.5rem]">
            <img
              className={isInactive ? 'opacity-30' : ''}
              src={MastercardIcon}
              alt="mastercard"
            />
            <span
              className={cn(
                'text-[11px] uppercase tracking-[1.1px] text-neutral03',
                {
                  'opacity-30': isInactive,
                },
              )}
            >
              GCARD
            </span>
          </div>
          {isInactive && (
            <div className="mt-auto flex w-full items-center justify-between">
              <div className="text-[20px] text-neutral02">Card Frozen</div>
              <FreezeIcon className="h-[24px] w-[24px]" />
            </div>
          )}
          <div className="mt-auto flex items-center justify-between leading-3">
            {!isLoading ? (
              <span
                className={cn(
                  'text-[11px] uppercase tracking-[1.1px] text-neutral03',
                  {
                    'opacity-30': isInactive,
                  },
                )}
              >
                BLACK
              </span>
            ) : (
              <div className="h-3 w-[50px] rounded-full bg-[#33383F]"></div>
            )}
            {!isLoading ? (
              <span className="text-[11px] uppercase tracking-[1.1px] text-neutral03">
                {getFormattedCardNumber(number!, true)}
              </span>
            ) : (
              <div className="h-3 w-[100px] rounded-full bg-[#33383F]"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
